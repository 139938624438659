import React, { createContext, useContext, useState, useEffect } from 'react';

const NotificationContext = createContext();

export function useNotifications() {
    return useContext(NotificationContext);
}

// Ensure useEffect is imported correctly
export function useOutsideAlerter(ref, onClose) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClose]);  // Include dependencies
}

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);

    const addNotification = (notification) => {
        setNotifications((prevNotifications) => [...prevNotifications, notification]);
    };

    const clearNotifications = () => {
        setNotifications([]);
    };

    const removeNotification = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
        );
    };

    const toggleShowNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                addNotification,
                clearNotifications,
                removeNotification,
                showNotifications,
                toggleShowNotifications,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
